export const formInputMixins = {
  props: {
    value: {
      type: [String, Number],
      default: () => null
    },
    bsTypeLabel: {
      type: Boolean,
      default: () => false
    }
  },

  computed: {
    innerValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    hasError() {
      return this.$attrs['error-messages'].length > 0
    },
  }
}
