<template>
  <!-- filled  -->
  <div>
    <template v-if="bsTypeLabel">
      <label class="app-black--text mb-3 d-block bs-style-label">{{ $attrs.label }}</label>
    </template>
    <v-text-field
      v-bind="$attrs"
      outlined
      :label="bsTypeLabel ? '' : $attrs.label"
      class="app-form-input"
      v-model="innerValue"
      color="#9DA3A9"
    >
      <template v-slot:append-outer>
        <slot name="append-outer"></slot>
      </template>

    </v-text-field>
  </div>
</template>

<script>
import { formInputMixins } from '@/mixins/formInputMixins'

export default {
  name: 'AppFormInput',
  mixins: [formInputMixins],
}
</script>

<style lang="scss">
/*.v-input.app-form-input.v-text-field.v-text-field--outlined:not(.error--text) {
  .v-label {
    color: #9F9F9F;
  }
  .v-input__control  > .v-input__slot {
    background: #FBFBFB;
    fieldset {
      border-color: #E8E8E8;
    }
    legend {
      color: #9F9F9F;
    }
  }
  &.dense-legend {
    legend{
      width: calc(100% - 4px)!important;
    }
  }
}*/
</style>
